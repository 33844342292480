import { QueryClient } from '@tanstack/react-query'
import { OperationDetails } from '~/routes/production_planning_pages/operations/types'
import { supabase } from '~/utils/supabaseClient'

export const operationDetailsQuery = (id: string) => ({
  queryKey: ['product', 'detail', id],
  queryFn: async () => {
    const request = supabase
      .from('operation_versions')
      .select(
        `
        *,
        profiles(
          profile_first_name,
          profile_last_name
        ),
        bill_of_operations_versions(*),
        operations(
          is_archived,
          operations_stations(
            factory_name,
            zone_name,
            station_name
          ),
          operation_taggings(
            operation_tag
          )
        )
      `,
      )
      .is('bill_of_operations_versions.is_current_version', true)
      .is('is_current_version', true)
      .eq('operation_id', id)
      .maybeSingle()

    const { data, error } = await request

    if (!data || error) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }

    return {
      ...data,
      operation_tags: data.operations?.operation_taggings.map((tag) => tag.operation_tag) ?? [],
      operations: {
        ...data.operations,
        operations_stations: data.operations?.operations_stations ?? [],
      },
    } as OperationDetails
  },
})

export const getOperationTags = (partnerName: string) => ({
  queryKey: ['operation_tags'],
  queryFn: async () => {
    const request = supabase.from('operation_tags').select('*').eq('partner_name', partnerName)

    const { data } = await request

    if (!data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return data
  },
})

export const getCurrencies = () => ({
  queryKey: ['currencies'],
  queryFn: async () => {
    const request = supabase.from('currencies').select('*')

    const { data } = await request

    if (!data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return data
  },
})

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = operationDetailsQuery(params.operationId)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }
