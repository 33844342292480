import { Filter } from '@saas-ui-pro/react'
import dayjs from 'dayjs'

export const getMultiSelectFilters = (filters: Filter[], filterId: string) => {
  return filters
    ?.filter((filter) => filter.id === filterId)
    ?.filter((filter) => filter?.operator === 'contains')
    ?.flatMap((filter) => {
      return filter?.value
    })
}

export const addStringValueFilter = (
  query: any,
  filters: Filter[],
  filterId: string,
  column: string,
) => {
  const operationNameFilters = filters?.filter((filter) => filter.id === filterId)
  operationNameFilters?.forEach((filter) => {
    switch (filter?.operator) {
      case 'is':
        query.eq(column, filter?.value)
        break
      case 'isNot':
        query.not(column, 'eq', filter?.value)
        break
      case 'contains':
        query.ilike(column, `%${filter?.value}%`)
        break
      case 'containsNot':
        query.not(column, 'ilike', `%${filter?.value}%`)
        break
    }
  })
}

export const addStringMultipleColumnsValueFilter = (
  query: any,
  filters: Filter[],
  filterId: string,
  columns: string[],
  reference?: any,
) => {
  const operationNameFilters = filters?.filter((filter) => filter.id === filterId)
  operationNameFilters?.forEach((filter) => {
    switch (filter?.operator) {
      case 'contains':
        query.or(
          columns?.map((column) => `${column}.ilike.*${filter?.value}*`).join(','),
          reference,
        )
        break
      case 'containsNot':
        query.and(
          columns?.map((column) => `${column}.not.ilike.*${filter?.value}*`).join(','),
          reference,
        )
        break
    }
  })
}

export const getProductFamilyFilters = (filters: Filter[]) => {
  return filters
    .filter((filter) => filter.id === 'productFamily')
    ?.map((filter) => {
      switch (filter?.operator) {
        case 'contains':
          return `code.ilike.*${filter?.value}*, group_title.ilike.*${filter?.value}*, sub_group_title.ilike.*${filter?.value}*, section_title.ilike.*${filter?.value}*, object_title.ilike.*${filter?.value}*`
        case 'containsNot':
          return `code.not.ilike.*${filter?.value}*, group_title.not.ilike.*${filter?.value}*, sub_group_title.not.ilike.*${filter?.value}*, section_title.not.ilike.*${filter?.value}*, object_title.not.ilike.*${filter?.value}*`
      }
    })
    .join(',')
}

export const addDateRangeFilters = (
  query: any,
  filters: Filter[],
  filterId: string,
  column: string,
) => {
  const startedFilters = filters?.filter((filter) => filter.id === filterId)
  startedFilters?.forEach((filter) => {
    if (filter?.operator === 'after') {
      query.gt(column, filter?.value)
    }
    if (filter?.operator === 'before') {
      query.lt(column, filter?.value)
    }
  })
}

export const addBooleanFilters = (
  query: any,
  filters: Filter[],
  filterId: string,
  column: string,
) => {
  const booleanFilters = filters?.filter((filter) => filter.id === filterId)
  booleanFilters?.forEach((filter) => {
    if (filter?.operator === 'is') {
      query.is(column, true)
    }
    if (filter?.operator === 'isNot') {
      query.is(column, false)
    }
  })
}

export const addBooleanFiltersHigherThanZero = (
  query: any,
  filters: Filter[],
  filterId: string,
  column: string,
) => {
  const booleanFilters = filters?.filter((filter) => filter.id === filterId)
  booleanFilters?.forEach((filter) => {
    if (filter?.operator === 'is') {
      query.gt(column, 0)
    }
    if (filter?.operator === 'isNot') {
      query.lt(column, 1)
    }
  })
}

export const addExactDateFilter = (
  query: any,
  filters: Filter[],
  filterId: string,
  column: string,
) => {
  const dateFilters = filters?.filter((filter) => filter.id === filterId)
  dateFilters?.forEach((filter) => {
    if (filter?.operator === 'is' && filter.value) {
      query.gt(column, dayjs(filter.value as string).format('YYYY-MM-DD 00:00'))
      query.lt('job_event_occurrence', dayjs(filter.value as string).format('YYYY-MM-DD 23:59'))
    }
  })
}

export const predefinedTimeSlotsFilter = (query, filters, id, column) => {
  const completedOnPredefinedFilter = filters
    ?.filter((filter) => filter.id === id)
    ?.filter((filter) => filter?.operator === 'on')
    ?.flatMap((filter) => {
      return filter?.value
    })
  if (completedOnPredefinedFilter && completedOnPredefinedFilter.length > 0) {
    const today = dayjs().startOf('day')

    if (completedOnPredefinedFilter.includes('today')) {
      query.gte(column, today)
    }

    if (completedOnPredefinedFilter.includes('this_week')) {
      const startOfThisWeek = dayjs().startOf('week')
      query.gte(column, startOfThisWeek)
    }

    if (completedOnPredefinedFilter.includes('this_month')) {
      const startOfThisMonth = dayjs().startOf('month')
      query.gte(column, startOfThisMonth)
    }
  }
}
