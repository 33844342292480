import { QueryClient } from '@tanstack/react-query'
import { Database } from '~/types/database.types'
import { supabase } from '~/utils/supabaseClient'

export type Device = Database['public']['Tables']['devices']['Row']

export const deviceDetailsQuery = (id: string) => ({
  queryKey: ['device', 'details', id],
  queryFn: async () => {
    const response = await supabase.from('devices').select().eq('device_id', id).single()
    if (!response.data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return response.data as Device
  },
})

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = deviceDetailsQuery(params.deviceId)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }
